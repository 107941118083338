import i18n from "@/utils/i18n"
import { faGlobeAmericas, faGlobeAsia } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import styled from "styled-components"

interface Props {
  value: string
  noLink: boolean
}

const Link = styled.a`
  color: #000;
  text-decoration: none;
  display: block;
`

const Option = ({ value, noLink }: Props) => (
  <>
    {noLink ? (
      <>
        <FontAwesomeIcon
          icon={value == "ja" ? faGlobeAsia : faGlobeAmericas}
          color="#000"
          style={{ marginRight: "8px", fontSize: "18px" }}
        />
        {i18n(`footer.language.${value}`)}
      </>
    ) : (
      <Link href={`/${value}`}>
        <FontAwesomeIcon
          icon={value == "ja" ? faGlobeAsia : faGlobeAmericas}
          color="#000"
          style={{ marginRight: "8px", fontSize: "18px" }}
        />
        {i18n(`footer.language.${value}`)}
      </Link>
    )}
  </>
)

export default Option
