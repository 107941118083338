import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Sizes } from "@/utils/vars"
import { Grey, White } from "@/utils/colors"

export const Wrapper = styled.footer`
  padding: 0 15px;
  z-index: 5;
  /* background-color: #25282a; */

  @media only screen and (min-width: ${Sizes.md}px) {
    padding: 60px 0;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1000px;
  padding: 15px 15px 0 15px;
`

export const LogoWrapper = styled.div`
  min-width: 125px;
  width: 50%;
  display: flex;
  flex-direction: column;

  > * {
    max-width: 200px;
  }

  @media only screen and (min-width: ${Sizes.md}px) {
    width: 250px;
  }
`

export const MediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
  justify-content: center;
  border-top: #31313c 2px solid;

  @media only screen and (min-width: ${Sizes.md}px) {
    width: 100%;
    justify-content: space-between;
  }
`

export const Disclaimer = styled.div`
  margin: 0 15px;
  text-align: center;
  font-family: "Proxima Nova";
  font-size: 16px;
  letter-spacing: 0.4px;
  color: ${Grey.lighter};
  width: 100%;
  text-align: center;
  flex-grow: 0;

  @media only screen and (min-width: ${Sizes.md}px) {
    width: auto;
    flex-grow: 1;
    order: 2;
    margin: 0 15px;
    padding: 0 15px;
    max-width: initial;
    align-items: center;
  }
`

export const ContactLink = styled.a`
  color: ${Grey.lighter};
  text-decoration: none;
  font-family: "Noto Sans JP";
  font-size: 14px;
  font-weight: 900;
  transition: all 0.25s ease;

  &:hover {
    color: ${White.normal};
  }

  &:visited {
    color: ${Grey.lighter};
  }
`

export const Title = styled.h3`
  font-family: "Noto Sans JP";
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
`

export const TitleDivider = styled.h3`
  width: 60px;
  height: 4px;
  border-radius: 2px;
  background-color: #fff;
`

export const Project = styled(AnchorLink)`
  font-family: "Noto Sans JP";
  font-size: 18px;
  font-weight: bold;
  color: #8b8b8b;
  margin-bottom: 14px;
  text-decoration: none;
  display: block;
  text-transform: uppercase;

  &:hover {
    color: #f84f01;
    transition: all 0.25s ease;
  }
`

export const Language = styled.div`
  order: 3;
  margin: 0 15px;
  width: 100%;

  @media only screen and (min-width: ${Sizes.md}px) {
    width: auto;
  }
`

export const PlayBrainText = styled.p`
  font-family: "Noto Sans JP";
  font-size: 14px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 0;
`

export const WrapRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: space-evenly;

  @media only screen and (min-width: ${Sizes.md}px) {
    flex-grow: 2;
    flex-direction: row;
    width: auto;
    align-items: center;
  }
`
