import React from "react"
import styled from "styled-components"
import { White, Black } from "@/utils/colors"

import "../../css/base.css"

const Root = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  background-color: ${Black.lighter};
  color: ${White.normal};
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
`

interface Props {
  children?: any
}

const PageRoot = ({ children }: Props) => <Root id="pageRoot">{children}</Root>

export default PageRoot
