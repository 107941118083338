import React from "react"

interface Props {
  style?: any
}

const Logo = ({ style }: Props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 192 1024 660"
    xmlSpace="preserve"
    style={style}
  >
    <g>
      <g>
        <g>
          <polygon
            style={{ fill: "#05ABF8" }}
            points="251.73,359.75 251.73,384.72 222.93,388.77 222.93,363.8 			"
          />
        </g>
        <g>
          <polygon
            style={{ fill: "#05ABF8" }}
            points="251.73,413.53 251.73,440.41 222.93,444.45 222.93,417.57 			"
          />
        </g>
        <g>
          <polygon
            style={{ fill: "#05ABF8" }}
            points="251.73,535.44 251.73,560.4 222.93,564.45 222.93,539.49 			"
          />
        </g>
        <g>
          <polygon
            style={{ fill: "#05ABF8" }}
            points="251.73,589.22 251.73,616.1 222.93,620.15 222.93,593.27 			"
          />
        </g>
        <g>
          <path
            style={{ fill: "#05ABF8" }}
            d="M389.79,395.25v31.49c0,14.59-6.53,20.89-14.98,25.15l-44.93,6.31c-8.45-1.89-14.98-6.34-14.98-20.94V410
                        l30.72-4.32v23.43l1.92,1.65l9.6-1.35l1.92-2.19v-21.12l-1.73-2.45l-31.68-9.76c-9.41-2.9-10.75-9.24-10.75-19.61V345.1
                        c0-14.59,6.53-20.89,14.98-25.15l44.93-6.31c8.45,1.89,14.98,6.34,14.98,20.94v25.73l-30.72,4.32v-21.89l-1.92-1.65l-9.6,1.35
                        l-1.92,2.19v20.16l2.5,2.72l30.91,8.14C388.45,378.16,389.79,384.88,389.79,395.25z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#05ABF8" }}
            d="M449.31,393.41v48l-30.72,4.32V307.48l59.91-8.42c8.45,1.89,14.98,6.34,14.98,20.94v44.16
                        c0,14.59-6.53,20.89-14.98,25.15L449.31,393.41z M449.31,328.13v40.32l11.52-1.62l1.92-2.19v-36.48l-1.92-1.65L449.31,328.13z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#05ABF8" }}
            d="M596.2,398c0,14.59-6.53,20.89-14.98,25.15l-44.93,6.31c-8.45-1.89-14.98-6.34-14.98-20.94v-92.17
                        c0-14.59,6.53-20.89,14.98-25.15l44.93-6.31c8.45,1.89,14.98,6.34,14.98,20.94V398z M565.48,313.99l-1.92-1.65l-9.6,1.35
                        l-1.92,2.19v84.49l1.92,1.65l9.6-1.35l1.92-2.19V313.99z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#05ABF8" }}
            d="M623.26,416.99V278.75l59.91-8.42c8.45,1.89,14.98,6.34,14.98,20.94v29.38c0,13.25-5.38,19.77-12.86,24.08
                        l16.51,41.84v19.39l-26.88,3.78l-17.66-51.28l-3.26,0.46v53.76L623.26,416.99z M653.98,299.39v34.56l11.52-1.62l1.92-2.19v-30.72
                        l-1.92-1.65L653.98,299.39z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#05ABF8" }}
            d="M779.56,285.57v109.45l-30.72,4.32V289.88l-22.08,3.1v-28.8l74.88-10.52v28.8L779.56,285.57z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#05ABF8" }}
            d="M905.14,322.82v31.49c0,14.59-6.53,20.89-14.98,25.15l-44.93,6.31c-8.45-1.89-14.98-6.34-14.98-20.94v-27.27
                        l30.72-4.32v23.43l1.92,1.65l9.6-1.35l1.92-2.19v-21.12l-1.73-2.45l-31.68-9.76c-9.41-2.9-10.75-9.24-10.75-19.61v-29.19
                        c0-14.59,6.53-20.89,14.98-25.15l44.93-6.31c8.45,1.89,14.98,6.34,14.98,20.94v25.73l-30.72,4.32v-21.89l-1.92-1.65l-9.6,1.35
                        l-1.92,2.19v20.16l2.5,2.72l30.91,8.14C903.8,305.73,905.14,312.46,905.14,322.82z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#05ABF8" }}
            d="M119.43,663.48V525.23l59.91-8.42c8.45,1.89,14.98,6.34,14.98,20.94v20.54c0,11.14-3.84,19.55-15.17,25.17
                        c11.33,2.44,15.17,9.77,15.17,20.91v25.54c0,14.59-6.53,20.89-14.98,25.15L119.43,663.48z M150.15,578.52l11.52-1.62l1.92-2.19
                        v-30.72l-1.92-1.65l-11.52,1.62V578.52z M163.59,632.31v-30.72l-1.92-1.65l-11.52,1.62v34.56l11.52-1.62L163.59,632.31z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#05ABF8" }}
            d="M337.17,632.86v-54.34l-23.04-57.44v-23.23l28.8-4.05l8.64,46.21l1.92-0.27l8.64-48.64l28.8-4.05v23.23
                        l-23.04,63.91v54.34L337.17,632.86z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#05ABF8" }}
            d="M493.66,587.82c0,14.59-6.53,20.89-14.98,25.15l-44.93,6.31c-8.45-1.89-14.98-6.34-14.98-20.94v-92.17
                        c0-14.59,6.53-20.89,14.98-25.15l44.93-6.31c8.45,1.89,14.98,6.34,14.98,20.94V587.82z M462.93,503.81l-1.92-1.65l-9.6,1.35
                        l-1.92,2.19v84.49l1.92,1.65l9.6-1.35l1.92-2.19V503.81z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#05ABF8" }}
            d="M569.32,461.95l26.88-3.78v138.25l-26.88,3.78l-19.2-57.59l-1.92,0.27v60.29l-26.88,3.78V468.7l26.88-3.78
                        l19.2,57.59l1.92-0.27V461.95z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#05ABF8" }}
            d="M697.96,559.24c0,14.59-6.53,20.89-14.98,25.15l-59.91,8.42V454.56l59.91-8.42
                        c8.45,1.89,14.98,6.34,14.98,20.94V559.24z M653.8,475.2v88.33l11.52-1.62l1.92-2.19v-84.49l-1.92-1.65L653.8,475.2z"
          />
        </g>
        <g>
          <g>
            <path
              style={{ fill: "#F2F2F2" }}
              d="M286.29,354.9l-63.36,8.91v-23.83c0-2.75,2.23-5.29,4.97-5.67l58.39-8.21V354.9z"
            />
          </g>
        </g>
        <g>
          <g>
            <polygon
              style={{ fill: "#F2F2F2" }}
              points="286.29,408.67 222.93,417.57 222.93,388.77 286.29,379.87 				"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{ fill: "#F2F2F2" }}
              d="M286.29,464.35l-58.39,8.21c-2.75,0.39-4.97-1.53-4.97-4.27v-23.83l63.36-8.91V464.35z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{ fill: "#F2F2F2" }}
              d="M286.29,530.58l-63.36,8.91v-23.83c0-2.75,2.23-5.29,4.97-5.67l58.39-8.21V530.58z"
            />
          </g>
        </g>
        <g>
          <g>
            <polygon
              style={{ fill: "#F2F2F2" }}
              points="286.29,584.35 222.93,593.25 222.93,564.45 286.29,555.55 				"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              style={{ fill: "#F2F2F2" }}
              d="M286.29,640.03l-58.39,8.21c-2.75,0.39-4.97-1.53-4.97-4.27v-23.83l63.36-8.91V640.03z"
            />
          </g>
        </g>
      </g>
      <g>
        <path
          style={{ fill: "#05ABF8" }}
          d="M165.99,746.15v18.61c0,8.63-3.86,12.35-8.85,14.86l-26.56,3.73c-4.99-1.11-8.85-3.75-8.85-12.37v-16.11
                    l18.16-2.55v13.85l1.13,0.98l5.68-0.8l1.13-1.29v-12.48l-1.02-1.45l-18.73-5.77c-5.56-1.72-6.36-5.46-6.36-11.59v-17.25
                    c0-8.62,3.86-12.34,8.85-14.86l26.56-3.73c4.99,1.11,8.85,3.75,8.85,12.37v15.21l-18.16,2.55v-12.94l-1.13-0.98l-5.68,0.8
                    l-1.13,1.29v11.92l1.48,1.61l18.27,4.81C165.19,736.05,165.99,740.03,165.99,746.15z"
        />
        <path
          style={{ fill: "#05ABF8" }}
          d="M208.54,740.63l-7.94,1.12v31.78l-18.16,2.55v-81.71l18.16-2.55v32.91l7.94-1.12V690.7l18.16-2.55v81.71
                    l-18.16,2.55V740.63z"
        />
        <path
          style={{ fill: "#05ABF8" }}
          d="M243.72,767.46v-17.02l3.41-0.48v-47.66l-3.41,0.48v-17.02l24.97-3.51v17.02l-3.41,0.48v47.66l3.41-0.48
                    v17.02L243.72,767.46z"
        />
        <path
          style={{ fill: "#05ABF8" }}
          d="M285.71,761.56v-81.71l35.41-4.98c4.99,1.11,8.85,3.75,8.85,12.37v12.14c0,6.58-2.27,11.55-8.97,14.88
                    c6.7,1.44,8.97,5.78,8.97,12.36v15.09c0,8.62-3.86,12.34-8.85,14.86L285.71,761.56z M303.87,711.34l6.81-0.96l1.13-1.29v-18.16
                    l-1.13-0.98l-6.81,0.96V711.34z M311.82,743.14v-18.16l-1.13-0.98l-6.81,0.96v20.43l6.81-0.96L311.82,743.14z"
        />
        <path
          style={{ fill: "#05ABF8" }}
          d="M365.15,734.51l1.14,0.97l4.54-0.64l1.13-1.29v-65.82l18.16-2.55v68.09c0,8.63-3.86,12.34-8.85,14.86
                    l-25.42,3.57c-4.99-1.11-8.85-3.75-8.85-12.37v-68.09l18.16-2.55V734.51z"
        />
        <path
          style={{ fill: "#05ABF8" }}
          d="M419.63,742.74v-32.12l-13.62-33.95v-13.73l17.02-2.39l5.11,27.31l1.14-0.16l5.11-28.75l17.02-2.39v13.73
                    l-13.62,37.77v32.12L419.63,742.74z"
        />
        <path
          style={{ fill: "#05ABF8" }}
          d="M488.63,717.15l-8.63,1.21l-1.36,16.08l-17.02,2.39V723.1l10.33-69.43l24.74-3.48l10.33,66.52v13.73
                    l-17.02,2.39L488.63,717.15z M481.48,701.13l5.67-0.8l-2.27-25.44l-1.13,0.16L481.48,701.13z"
        />
      </g>
    </g>
  </svg>
)

export default Logo
