import React from "react"
import { SizeProp } from "@fortawesome/fontawesome-svg-core"
import { Link, Wrapper, Text } from "./styles"

interface Props {
  to: string
  children?: any
  size?: SizeProp
  title?: string
  className?: any
}

const IconLink = ({ to, children, title, size, className }: Props) => (
  <Link
    href={to}
    target="_blank"
    rel="noopener noreferrer"
    className={className}
  >
    <Wrapper size={size || "2x"}>
      {children}
      {title && <Text>{title} </Text>}
    </Wrapper>
  </Link>
)

export default IconLink
