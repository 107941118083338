import { Sizes } from "@/utils/vars"
import React from "react"
import styled from "styled-components"

const Root = styled.div`
  padding: 128px 15px;
  margin-top: -128px;
  clip-path: polygon(0% 100%, 100% calc(100% - 128px), 100% 0%, 0% 128px);

  @media only screen and (min-width: ${Sizes.md}px) {
    padding: 128px 32px;
  }

  @media only screen and (min-width: ${Sizes.lg}px) {
    padding: 128px 64px;
  }
  position: relative;
`

interface Props {
  style?: any
  children?: any
}

const Section = ({ style, children }: Props) => (
  <Root style={style}>{children}</Root>
)

export default Section
