import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import OgImage from "@/assets/images/ogImage.png"
import i18n from "@/utils/i18n"

interface Props {
  description?: string
  lang?: string
  meta?: []
  title: string
}

const SEO = ({ description, lang, meta, title }: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            ogTitle
            description
            ogDescription
          }
        }
      }
    `
  )

  const siteUrl = site.siteMetadata.siteUrl
  const ogTitle =
    i18n("home.seo.title") || site.siteMetadata.ogTitle || "Esports Beyond"
  const ogDescription =
    i18n("home.seo.description") ||
    site.siteMetadata.ogDescription ||
    "Esports Beyondは個人、渋谷区、企業がesportsを中心に、教育、福祉、地域創生、産業振興につながるシステムと、新たなカルチャーを生み出す企画などを生み出すプロジェクトです。"
  const metaCreator = "PlayBrain"
  const ogImageUrl = OgImage

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: ogDescription,
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: ogDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:image",
          name: "og:image",
          content: `${siteUrl}${ogImageUrl}`,
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: metaCreator,
        },
        {
          name: `twitter:title`,
          content: ogTitle,
        },
        {
          name: `twitter:description`,
          content: ogDescription,
        },
        {
          name: `twitter:image`,
          content: `${siteUrl}${ogImageUrl}`,
        },
      ].concat(meta || [])}
    >
      <link rel="alternate" href={`${site.siteMetadata.siteUrl}/ja`} hrefLang={'x-default'} key={'x-default'}/>
      <link rel="alternate" href={`${site.siteMetadata.siteUrl}/ja`} hrefLang={'ja'} key={'ja'}/>
      <link rel="alternate" href={`${site.siteMetadata.siteUrl}/en`} hrefLang={'en'} key={'en'}/>
    </Helmet>
  )
}

export default SEO
