import React from "react"
import { faTwitter, faLine, faYoutube, faTwitch } from "@fortawesome/free-brands-svg-icons"
import { Container, Icon } from "./styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import i18n from "@/utils/i18n"

const SocialMedia = () => {
  const url = typeof window !== "undefined" ? window.location.href : ""

  return (
    <Container>
      <Icon size="lg" to={String(process.env.GATSBY_SOCIAL_MEDIA_TWITTER_URL)}>
        <FontAwesomeIcon icon={faTwitter} size="lg" />
      </Icon>
      <Icon size="lg" to={String(process.env.GATSBY_SOCIAL_MEDIA_YOUTUBE_URL)}>
        <FontAwesomeIcon icon={faYoutube} size="lg" />
      </Icon>
      <Icon size="lg" to={String(process.env.GATSBY_SOCIAL_MEDIA_TWITCH_URL)}>
        <FontAwesomeIcon icon={faTwitch} size="lg" />
      </Icon>
      <Icon
        size="lg"
        to={`http://line.me/R/msg/text/?${encodeURIComponent(
          i18n("home.landing.socialLinks.lineShare")
        )}%0A${encodeURIComponent(url)}`}
      >
        <FontAwesomeIcon icon={faLine} size="lg" />
      </Icon>
    </Container>
  )
}

export default SocialMedia
