import get from "lodash.get"
import { sprintf } from "sprintf-js"

export const availableLanguages = ["en", "ja"]

let pageLang = 'ja'

export const setPageLang = (lang: string) => pageLang = lang

export const getLanguage = () => {
  if (typeof window !== "undefined") {
    const loc = window.location.href

    if (loc.indexOf('/en') !== -1) {
      pageLang = 'en'
      return 'en'
    } else {
      return 'ja'
    }
  } else {
    return pageLang
  }
}

const i18n = (id: string, ...substitutions: any[]): string => {
  let lang = getLanguage()
  let data = null
  try {
    data = require(`./i18n/${lang}.json`)
  } catch (error) {}
  if (!data) {
    try {
      data = require(`./i18n/ja.json`)
    } catch (error) {
      return ""
    }
  }
  const response = get(data, id, null)
  if (!response) {
    try {
      data = require(`./i18n/ja.json`)
    } catch (error) {
      return ""
    }
  }
  return sprintf(get(data, id, ""), ...substitutions)
}

export default i18n
