import styled from "styled-components"
import IconLink from "@/components/Common/Buttons/IconLink"
import { Sizes } from "@/utils/vars"

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  order: 2;
  width: 50%;
  margin-top: -8px;
  max-width: 100%;
  margin: 0 auto;

  @media only screen and (min-width: ${Sizes.md}px) {
    order: 3;
    width: auto;
    margin-top: 0px;
  }
`

export const Icon = styled(IconLink)`
  @media only screen and (min-width: ${Sizes.sm}px) {
    padding: 0 4px;
  }
`
