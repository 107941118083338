import React from "react"
import styled from "styled-components"
import Select from "react-select"
import { getLanguage, availableLanguages } from "@/utils/i18n"
import Option from "./Option"
import { Sizes } from "@/utils/vars"

const Container = styled.div`
  width: 100%;

  @media only screen and (min-width: ${Sizes.md}px) {
    min-width: 200px;
  }
`

const colourStyles = {
  control: (styles: any) => ({
    ...styles,
    borderRadius: 0,
    backgroundColor: "white",
    border: 0,
  }),
  container: (styles: any) => ({ ...styles, outline: 0 }),
  // @ts-ignore
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    const color = "#c2c2c2"
    return {
      ...styles,
      width: "100%",
      fontFamily: "Noto Sans JP",
      fontSize: "14px",
      fontWeight: "bold",
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "null"
        : isFocused
        ? "#c2c2c2"
        : null,
      color: "#010101",
      cursor: "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#c2c2c2" : "#c2c2c2"),
        color: "#010101",
      },
    }
  },
  input: (styles: any) => ({ ...styles, fontFamily: "Noto Sans JP" }),
  placeholder: (styles: any) => ({ ...styles, fontFamily: "Noto Sans JP" }),
  singleValue: (styles: any, { data }: any) => ({
    ...styles,
    fontFamily: "Noto Sans JP",
    fontSize: "14px",
    fontWeight: "bold",
  }),
  menu: (styles: any) => ({
    ...styles,
    fontFamily: "Noto Sans JP",
    borderRadius: 0,
    margin: 0,
    padding: 0,
  }),
}

const LanguageSelect = () => {
  const onChange = (value: any) => {
    console.log(value)
  }

  return (
    <Container>
      <Select
        defaultValue={{
          value: getLanguage(),
          label: <Option value={getLanguage()} noLink={true} />,
        }}
        onChange={e => onChange(e)}
        options={availableLanguages.map(l => ({
          label: <Option value={l} noLink={false} />,
          value: l,
        }))}
        styles={colourStyles}
        isSearchable={false}
        menuPlacement="top"
      />
    </Container>
  )
}

export default LanguageSelect
