import React from "react"
import i18n from "@/utils/i18n"
import SocialMedia from "./SocialMedia"
import {
  Wrapper,
  Container,
  MediaContainer,
  LogoWrapper,
  Disclaimer,
  Language,
  ContactLink,
  PlayBrainText,
  WrapRight,
} from "./styles"
import LanguageSelect from "./LanguageSelect"
import Logo from "@/components/Home/Icons/Logo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGamepad } from "@fortawesome/free-solid-svg-icons"

interface Props {
  id?: any
}

const Footer = ({ id }: Props) => (
  <Wrapper id={id}>
    <Container>
      <MediaContainer>
        <LogoWrapper>
          <Logo />
          <SocialMedia />
        </LogoWrapper>
        <WrapRight>
          <Disclaimer>
            {/* <Title>{i18n("footer.projects.title")}</Title>
            <TitleDivider />
            <Project href="#homeInfo" offset="32">
              {i18n("footer.projects.innovationWeek")}
            </Project> */}
            <ContactLink href="mailto:contact@esportsbeyond.gg">{i18n("footer.contactUs")}</ContactLink>
            <PlayBrainText>{i18n("footer.createdByPrefix")}
            <FontAwesomeIcon icon={faGamepad} />
            {i18n("footer.createdBySuffix")} ©{new Date().getFullYear()}</PlayBrainText>
          </Disclaimer>
          <Language>
            <LanguageSelect />
          </Language>
        </WrapRight>
      </MediaContainer>
    </Container>
  </Wrapper>
)

export default Footer
