import styled from "styled-components"
import { SizeProp } from "@fortawesome/fontawesome-svg-core"
import OutboundLink from "@/components/Common/Analytics/OutboundLink"
import { White, Green } from "@/utils/colors"

const SizeFromProp = (size?: SizeProp) => {
  switch (size) {
    case "xs":
    case "lg":
      return "32px"
    default:
      return "64px"
  }
}

const IconSizeFromProp = (size?: SizeProp) => {
  switch (size) {
    case "xs":
    case "lg":
      return "24px"
    default:
      return "32px"
  }
}

interface ScaleProp {
  size?: SizeProp
}

export const Link = styled(OutboundLink)`
  text-decoration: none;
  color: ${White.normal};

  &:hover {
    color: ${Green.normal};
  }
`

export const Text = styled.span`
  position: absolute;
  bottom: 0px;
  font-family: "Noto Sans JP";
  font-size: 12px;
  opacity: 0;
  transition: all 0.25s ease;
`

export const Wrapper = styled.div`
  width: ${({ size }: ScaleProp) => SizeFromProp(size)};
  height: ${({ size }: ScaleProp) => SizeFromProp(size)};
  border-radius: 50%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 2px;
  color: ${White.normal};
  cursor: pointer;
  transition: all 0.25s ease;
  color: ${White.normal};

  &:hover {
    color: ${Green.normal};

    ${Text} {
      color: ${Green.normal};
      opacity: 1;
      bottom: -8px;
    }
  }

  > svg {
    width: ${({ size }: ScaleProp) => IconSizeFromProp(size)};
    height: ${({ size }: ScaleProp) => IconSizeFromProp(size)};
  }
`
