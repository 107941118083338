export const Black = {
  normal: "#000",
  lighter: "#1c1c1f",
  lightest: "#24242a",
}

export const White = {
  normal: "#fff",
}

export const Green = {
  normal: "#07a1ea",
}

export const Grey = {
  normal: "#989898",
  lighter: "#9aa4af",
}

export const Red = {
  normal: "#F00",
  darker: "#bd2020",
}

export const Blue = {
  normal: "#05abf8",
}
