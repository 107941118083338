import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { CreateLabel } from "@/utils/analytics"

const ExtendedOutboundLink = (props: any) => (
  <OutboundLink
    {...props}
    eventLabel={
      typeof props.eventLabel === "object"
        ? CreateLabel({ url: props.href, ...props.eventLabel })
        : !props.eventLabel
        ? CreateLabel({ url: props.href })
        : props.eventLabel
    }
  />
)

export default ExtendedOutboundLink
