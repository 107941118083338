import React from "react"
import styled from "styled-components"

const Root = styled.div`
  flex: 1;
`

interface Props {
  children?: any
}

const PageContent = ({ children }: Props) => <Root>{children}</Root>

export default PageContent
